@import "src/scss/module";

$grey: #e7e8e9;

.BlogPostsCarousel {
  position: relative;

  :global {
    .swiper {
      background-color: $grey;
      border-left: 1px solid $grey;
    }

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      height: auto;
    }

    .navigation {
      @include media-breakpoint-up(md) {
        position: absolute;
        left: -6rem;
        bottom: 0;
      }
    }
  }
}
